import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../services/axios";
import { useEffect } from "react";
import "./ProductDetail.css";
import { Helmet } from "react-helmet-async";

const ProductDetail = () => {
  const [productDetail, setProductDetail] = useState({});
  const { id } = useParams();
  const { seoUrlTitle } = useParams();
  const navigate = useNavigate();

  // const shoplink =
  //   productDetail.data &&
  //   JSON.parse(productDetail.data.shopLink)?.map((item) => item.Url);
  // const shopLinkName =
  //   productDetail.data &&
  //   JSON.parse(productDetail.data.shopLink)?.map((item) => item.Name);

  const getProductDetail = async () => {
    const response = await axios.get(
      `/api/V1/Ui/Product/GetProductDetailForUi/${id}`,
      {
        headers: {
          SubSystemKey: window.Configs["sub-system-key"],
        },
      }
    );
    return response.data;
  };

  useEffect(() => {
    const fetchProductDetail = async () => {
      //   setProductDetail(await getProductDetail());
      // };
      // fetchProductDetail();

      const d = await getProductDetail();

      if (encodeURI(d.data.seoUrlTitle) !== encodeURI(seoUrlTitle)) {
        navigate("/notFound");
      } else {
        setProductDetail(d);
      }
    };
    fetchProductDetail();
  }, []);

  const canonical = window.location.href;

  return (
    <section className="container product-detail box_shodow">
      <Helmet>
        <title>
          {`زر اویل / ${
          productDetail.data && productDetail.data.seoTitle
        }`}
        </title>
        <link rel="canonical" href={canonical} />
        <meta
          name="twitter:title"
          content={productDetail.data && productDetail.data.title}
        />
        <meta
          name="twitter:description"
          content={productDetail.data && productDetail.data.seoSummary}
        />
        <meta
          name="twitter:image"
          content={
            productDetail.data &&
            (productDetail.data.seoImage
              ? productDetail.data.seoImage
              : productDetail.data.image)
          }
        />
        <meta
          property="og:title"
          content={productDetail.data && productDetail.data.title}
        />
        <meta
          property="og:description"
          content={productDetail.data && productDetail.data.seoSummary}
        />
        <meta
          property="og:image"
          content={
            productDetail.data &&
            (productDetail.data.seoImage
              ? productDetail.data.seoImage
              : productDetail.data.image)
          }
        />
      </Helmet>
      <nav aria-label="Breadcrumb" className="breadcrumb">
        <ol>
          <li>
            <Link to="/" className="breadcrumb-items">
              صفحه اصلی
            </Link>
          </li>
          <li>
            <Link to="/products" className="breadcrumb-items">
              محصولات
            </Link>
          </li>
          <li>{productDetail.data && productDetail.data.title}</li>
        </ol>
      </nav>
      <div className="wrapper">
        <figure className="img-container ">
          <img
            src={`${
              window.Configs["ui-base-url"]
            }/Api/V1/ui/Content/Image?entityName=0&imageName=${
              productDetail.data && productDetail.data.image
            }`}
            alt={productDetail.data && productDetail.data.title}
          />
        </figure>

        <div className="info-container ">
          <h1>{productDetail.data && productDetail.data.title}</h1>
          <p>{productDetail.data && productDetail.data.summary}</p>
          <div
            dangerouslySetInnerHTML={{
              __html: productDetail.data && productDetail.data.body,
            }}
          ></div>
        </div>
        {productDetail.data &&
          JSON.parse(productDetail.data.shopLink)?.map((item, index) => (
            <div className="shoplink-container" key={index}>
              <p className="shoplink">
                لینک خرید از سایت <a href={item.Url}> {item.Name}</a>
              </p>
            </div>
          ))}

        <div className="button-container ">
          <Link to="/">
            <button className="btn_shadow">
              صفحه اصلی <i className="fa fa-home"></i>
            </button>
          </Link>
          {/* <a href={shoplink}>
            <button className="btn_shadow">
              خرید <i className="fa fa-shopping-cart"></i>
            </button>
          </a> */}
        </div>
      </div>
    </section>
  );
};

export default ProductDetail;
