import React, { useState, useEffect, useContext } from "react";
import "./OilProduct.css";
import OilProductSlide from "./OilProductSlide";
import { dataContext } from "../context/DataContextProvider";

const OilProduct = () => {
  // const [data, setdata] = useState(OilProductApi);
  const [index, setIndex] = useState(0);
  const { oilProducts } = useContext(dataContext);

  useEffect(() => {
    const lastIndex = (oilProducts.data && oilProducts.data.length) - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, oilProducts.data]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 6000);
    return () => clearInterval(slider);
  }, [index]);

  return (
    <>
      <section className="oil-product" id="oil-product">
        <div className="container">
          <div className="heading text-center">
            <h4>محصولات</h4>
            <h1>زر اویل</h1>
          </div>
          <div className="slide">
            {oilProducts.data?.map((value, valueIndex) => {
              return (
                <OilProductSlide
                  key={valueIndex}
                  {...value}
                  valueIndex={valueIndex}
                  index={index}
                />
              );
            })}
            <div className="slide_button">
              <button
                className="btn_shadow next_btn"
                onClick={() => setIndex(index + 1)}
              >
                <i className="fa fa-arrow-right"></i>
              </button>
              <button
                className="btn_shadow prev_btn"
                onClick={() => setIndex(index - 1)}
              >
                <i className="fa fa-arrow-left"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OilProduct;
