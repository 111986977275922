import React, { useContext } from "react";
import "../Blog/Blog.css";
import Loading from "../loading/Loading";
import BlogCard from "./BlogCard";
import ReactPaginate from "react-paginate";
import { dataContext } from "../../context/DataContextProvider";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const Blog = () => {
  const {
    pageNumBlog,
    pageSizeBlog,
    setPageSizeBlog,
    pageCountBlog,
    setPageNumBlog,
    isLoadingBlog,
    allBlogs,
  } = useContext(dataContext);

  const initialPageSizeBlog = 12;

  const handlePageClick = (e) => {
    setPageNumBlog(e.selected + 1);
  };

  const canonical = window.location.href;

  return (
    <>
      {isLoadingBlog ? (
        <Loading />
      ) : (
        <section className="Portfolio Blog" id="blog">
          <Helmet>
            <title>زر اویل / اخبار و مقالات</title>
            <link rel="canonical" href={canonical} />
          </Helmet>{" "}
          <div className="container top">
            <div className="heading text-center">
              <h1>اخبار و مقالات</h1>
            </div>

            {allBlogs.data?.totalCount >= initialPageSizeBlog && (
              <div className="paginate-container">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="صفحه بعد >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCountBlog}
                  previousLabel="< صفحه قبل"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousLinkClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                  forcePage={pageCountBlog === 0 ? -1 : pageNumBlog - 1}
                />
                <select
                  value={pageSizeBlog}
                  onChange={(e) => setPageSizeBlog(e.target.value)}
                  className="selector h-25 mx-2 rounded text-primary border-primary"
                >
                  <option value={12}>12</option>
                  <option value={24}>24</option>
                  <option value={48}>48</option>
                </select>
              </div>
            )}

            <div className="content">
              {allBlogs &&
                allBlogs.data &&
                allBlogs.data?.items?.map((value, index) => {
                  return <BlogCard key={index} {...value} />;
                })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Blog;
