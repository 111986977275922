import React from "react";
import { shorten } from "../../helper/function";
import { Link } from "react-router-dom";

const Card = ({
  title,
  summary,
  id,
  image,
  specification,
  seoUrlTitle,
  shopLink,
}) => {
  const linkShop = shopLink && JSON.parse(shopLink)?.map((item) => item.Url);

  return (
    <>
      <div className="box container">
        {/* <figure> */}
          <img
            src={`${window.Configs["ui-base-url"]}/Api/V1/ui/Content/Image?entityName=0&imageName=${image}`}
            alt={title}
          />
        {/* </figure> */}
        <div className="h2-container">
          <Link to={`/productDetail/${id}/${seoUrlTitle}`}>
            <h2>{title}</h2>
          </Link>
        </div>
        <div className="p-container">
          <p>{`${shorten(summary, 80)}`}</p>
        </div>
        <div className="containerButtons">
          <Link to={`/productDetail/${id}/${seoUrlTitle}`}>
            <i className="fa fa-arrow-right"></i>
            <span>ادامه مطلب ...</span>
          </Link>
          <a href={linkShop}>
            <i className="fa fa-shopping-cart">
              <span>خرید</span>
            </i>
          </a>
        </div>
      </div>
    </>
  );
};

export default Card;
