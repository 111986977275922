import React, { useContext } from "react";
import "./About.css";
import Typewriter from "typewriter-effect";

import { dataContext } from "../../context/DataContextProvider";

const About = ({myRef}) => {
  const { aboutUsWebpart } = useContext(dataContext);
  const filetrKeyAboutUs = (key) => {
    if (aboutUsWebpart.data) {
      const result = JSON.parse(aboutUsWebpart.data.items[0].specification).filter(
        (item) => {
          return item.Key === key;
        }
      );
      return result;
    }
  };

  const blackTitle =
    aboutUsWebpart.data &&
    filetrKeyAboutUs("blackTitle").map((item) => item.Value);

  const redTitle =
    aboutUsWebpart.data &&
    filetrKeyAboutUs("redTitle").map((item) => item.Value);

  const typewriter1 =
    aboutUsWebpart.data &&
    filetrKeyAboutUs("typewriter1").map((item) => item.Value);

  const typewriter2 =
    aboutUsWebpart.data &&
    filetrKeyAboutUs("typewriter2").map((item) => item.Value);

  const image =
    aboutUsWebpart.data && filetrKeyAboutUs("image").map((item) => item.Value);

  // const filterRedTitle =
  //   aboutUsWebpart &&
  //   aboutUsWebpart.data &&
  //   aboutUsWebpart.data.specification &&
  //   JSON.parse(aboutUsWebpart.data.specification).filter((item) => {
  //     return item.Key === "redTitle";
  //   });
  // const redTitle = filterRedTitle && filterRedTitle.map((item) => item.Value);

  return (
      <article className="hero container" id="about" ref={myRef}>
        <div className="container f_flex top">
          <div className="left">
            <h1>
              {blackTitle} <span>{redTitle}</span>
            </h1>
            <h2>
              به
              <span>
                <Typewriter
                  options={{
                    strings: [
                      typewriter1 && typewriter1.toString(),
                      typewriter2 && typewriter2.toString(),
                    ],
                    autoStart: true,
                    loop: true,
                    delay: 75,
                  }}
                />
              </span>
            </h2>

            <p
              dangerouslySetInnerHTML={{
                __html: aboutUsWebpart.data && aboutUsWebpart.data.items[0].body,
              }}
            ></p>
          </div>
          <div className="right">
            <figure className="right_img ">
              <img src={image} alt="oil" />
            </figure>
          </div>
        </div>
      </article>
  );
};

export default About;
