import React, { useContext } from "react";
import "./AboutProduct.css";
import { dataContext } from "../../context/DataContextProvider";
import { useNavigate } from "react-router-dom";

const AboutProduct = () => {
  const { homeBlog } = useContext(dataContext);
  const navigate = useNavigate()

  return (
    // <AboutProductCard {...item} key={index} />
      <article id="about-product" className="about-product container">
        <div className="">
          <div className="heading text-center">
            <h4>اخبار و مقالات</h4>
            <h1>زر اویل</h1>
          </div>

          <div className="content-section mtop d_flex">
            <div className="left">
              <div className="content">
                {homeBlog.data?.slice(0, 2).map((item, index) => (
                  <div className="box" key={index} onClick={() => navigate(`/blogDetail/${item.id}/${item.seoUrlTitle}`)}>
                    <div className="title_content d_flex">
                      <div className="title">
                        <h2>{item.title}</h2>
                      </div>
                    </div>
                    <hr />
                    <p>{item.summary}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="left">
              <div className="content">
                {homeBlog.data?.slice(-2).map((item, index) => (
                  <div className="box btn_shadow" key={index} onClick={() => navigate(`/blogDetail/${item.id}/${item.seoUrlTitle}`)} >
                    <div className="title_content d_flex">
                      <div className="title">
                        <h2>{item.title}</h2>
                      </div>
                    </div>
                    <hr />
                    <p>{item.summary}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </article>
    // <div dangerouslySetInnerHTML={{__html :aboutProductWebpart.data && aboutProductWebpart.data.items[0].body}}>
    /* <section className="about-product" id="about-product">
        <div className="container top">
          <div className="heading text-center">
            <h4>درباره محصولات</h4>
            <h1>زر اویل</h1>
          </div>

          <div className="content-section mtop d_flex">
            <div className="left">
              <div className="content">
                {aboutProductApi.map((val, id) => {
                  if (val.side === "right") {
                    return (
                      <AboutProductCard
                        key={id}
                        title={val.title}
                        properties={val.properties}
                        rate={val.rate}
                        desc={val.desc}
                      />
                    );
                  }
                })}
              </div>
            </div>

            <div className="left">
              <div className="content">
                {aboutProductApi.map((val, index) => {
                  if (val.side === "left") {
                    return (
                      <AboutProductCard
                        key={index}
                        title={val.title}
                        properties={val.properties}
                        rate={val.rate}
                        desc={val.desc}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </section> */
    // </div>
  );
};

export default AboutProduct;
