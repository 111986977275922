import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { dataContext } from "../../context/DataContextProvider";
import OilProduct from "../../OilProduct/OilProduct";
import About from "../About/About";
import AboutProduct from "../AboutProduct/AboutProduct";
import Recipes from "../Recipes/Recipes";
import Slideshow from "../Slideshow/Slideshow";
import Loading from "./../loading/Loading";

const Home = ({ myRef }) => {
  const { isLoadingHome } = useContext(dataContext);

  // window.addEventListener("scroll", function () {
  //   const header = this.document.querySelector(".header");
  //   header.classList.toggle("active", this.window.scrollY > 100);
  // });

  const canonical = window.location.href;
  return (
    <div>
      <Helmet>
        <title>زر اویل</title>
        <link rel="canonical" href={canonical} />
      </Helmet>
      <main>
        {isLoadingHome ? (
          <div>
            <Loading />
            <Slideshow />
          </div>
        ) : (
          <div>
            <Slideshow />
            <About myRef={myRef} />
            <OilProduct />
            <Recipes />
            <AboutProduct />
          </div>
        )}
      </main>
    </div>
  );
};

export default Home;
