import { useRef , useEffect } from "react";
import "./App.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import Home from "./components/Home/Home";
import ProductDetail from "./components/Products/ProductDetail";
import RecipesDetail from "./components/Recipes/RecipesDetail";
import ContactUs from "./components/ContactUs/ContactUs";
import Products from "./components/Products/Products";
import Header from "./components/Head/Header";
import Footer from "./components/Footer/Footer";
import Blog from "./components/Blog/Blog";
import DataContextProvider from "./context/DataContextProvider";
import NotFound from "./components/NotFound/NotFound";
import BlogDetaills from "./components/Blog/BlogDetaills";
import AllRecieps from "./components/Recipes/AllRecieps";
// import SiteMap from "./components/sitemap/SiteMap";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const u = window.Redirect.filter(
      (x) => x["old-url"].toLowerCase().trim() === decodeURI(window.location.pathname).toLowerCase().trim()
    );
    if (u.length) navigate(u[0]["new-url"]);
  }, []);

  const myRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 30);

  const executeScroll = () => scrollToRef(myRef);

  return (
    <DataContextProvider>
      <Header executeScroll={executeScroll} />
      <Routes>
        <Route path="/" element={<Home myRef={myRef} />} />
        <Route path="/products" element={<Products />} />
        <Route
          path="/productDetail/:id/:seoUrlTitle"
          element={<ProductDetail />}
        />
        <Route
          path="/recipesDetail/:id/:seoUrlTitle"
          element={<RecipesDetail />}
        />
        <Route path="/allRecieps" element={<AllRecieps />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogDetail/:id/:seoUrlTitle" element={<BlogDetaills />} />
        {/* <Route path="/sitemap.xml" element={<SiteMap />} /> */}
        <Route path="/notFound" element={<NotFound />} />
      </Routes>
      <Footer />
    </DataContextProvider>
  );
}

export default App;
