import React, { useContext } from "react";
import "./Products.css";
import "./ResponsiveP.css";
import Card from "./Card";
import { dataContext } from "../../context/DataContextProvider";
import Loading from "../loading/Loading";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const Products = () => {
  const {
    allProducts,
    isLoadingProduct,
    pageNumProduct,
    pageSizeProduct,
    setPageSizeProduct,
    pageCountProduct,
    setPageNumProduct,
  } = useContext(dataContext);

  const initialPageSize = 12;

  const handlePageClick = (e) => {
    setPageNumProduct(e.selected + 1);
  };

  const canonical = window.location.href;
  return (
    <>
      {isLoadingProduct ? (
        <Loading />
      ) : (
        <section className="features " id="products">
          <Helmet>
            <title>زر اویل / محصولات</title>
            <link rel="canonical" href={canonical} />
          </Helmet>
          <div className="container">
            <div className="heading-2 text-center">
              <h1> محصولات زر اویل</h1>
            </div>

            {allProducts.data?.totalCount >= initialPageSize && (
              <div className="paginate-container">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="صفحه بعد >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCountProduct}
                  previousLabel="< صفحه قبل"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-num"
                  previousLinkClassName="page-num"
                  nextLinkClassName="page-num"
                  activeLinkClassName="active"
                  forcePage={pageCountProduct === 0 ? -1 : pageNumProduct - 1}
                />
                <select
                  value={pageSizeProduct}
                  onChange={(e) => setPageSizeProduct(e.target.value)}
                  className="selector h-25 mx-2 rounded text-primary border-primary"
                >
                  <option value={12}>12</option>
                  <option value={24}>24</option>
                  <option value={48}>48</option>
                </select>
              </div>
            )}

            <div className="content ">
              {allProducts.data !== undefined &&
                allProducts.data.items.map((val, index) => {
                  return <Card key={index} {...val} valueIndex={index} />;
                })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Products;
