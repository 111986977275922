import React, { useContext } from "react";
import { dataContext } from "../../context/DataContextProvider";
import RecipesCard from "./RecipesCard";
import Loading from "./../loading/Loading";
import { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Helmet } from "react-helmet-async";

const AllRecieps = () => {
  const {
    allReciepe,
    isLoadingRecieps,
    pageNumReciepe,
    pageSizeReciepe,
    setPageSizeReciepe,
    pageCountReciepe,
    setPageNumReciepe,
  } = useContext(dataContext);

  const initialPageSizeReciepe = 12;

  const handlePageClick = (e) => {
    setPageNumReciepe(e.selected + 1);
  };

  useEffect(() => {
  }, [allReciepe]);
  
  const canonical = window.location.href;
  return (
    <section>
      {isLoadingRecieps ? (
        <Loading />
      ) : (
        <section className="recipes" id="recipes">
          <Helmet>
            <title>زر اویل / دستور پخت</title>
            <link rel="canonical" href={canonical} />
          </Helmet>
          <div className="heading-2 text-center">
            <h1> دستور پخت با زر اویل</h1>
          </div>

          {allReciepe.data?.totalCount >= initialPageSizeReciepe && (
            <div className="paginate-container">
              <ReactPaginate
                breakLabel="..."
                nextLabel="صفحه بعد >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCountReciepe}
                previousLabel="< صفحه قبل"
                renderOnZeroPageCount={null}
                containerClassName="pagination"
                pageLinkClassName="page-num"
                previousLinkClassName="page-num"
                nextLinkClassName="page-num"
                activeLinkClassName="active"
                forcePage={pageCountReciepe === 0 ? -1 : pageNumReciepe - 1}
              />
              <select
                value={pageSizeReciepe}
                onChange={(e) => setPageSizeReciepe(e.target.value)}
                className="selector h-25 mx-2 rounded text-primary border-primary"
              >
                <option value={12}>12</option>
                <option value={24}>24</option>
                <option value={48}>48</option>
              </select>
            </div>
          )}
          <div className="content ">
            {allReciepe.data?.items.map((value, index) => {
              return <RecipesCard key={index} {...value} />;
            })}
          </div>
        </section>
      )}
    </section>
  );
};

export default AllRecieps;
