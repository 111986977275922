import React, { useEffect, useState } from "react";
import axios from "../../services/axios";
import "./RecipesDetail.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const RecipesDetail = () => {
  const [reciepeDetail, setReciepeDetail] = useState([]);
  const { id } = useParams();
  const { seoUrlTitle } = useParams();
  const navigate = useNavigate();

  const getReciepeDetail = async () => {
    const response = await axios.get(
      `/api/V1/Ui/Recipe/GetRecipeDetailForUi/${id}`,
      {
        headers: {
          SubSystemKey: window.Configs["sub-system-key"],
        },
      }
    );
    return response.data.data;
  };

  useEffect(() => {
    // const img = document.getElementById("img");
    // console.log(img.getBoundingClientRect());
    const fetchApiReciepeHome = async () => {
      const d = await getReciepeDetail();

      if (encodeURI(d.seoUrlTitle) !== encodeURI(seoUrlTitle)) {
        navigate("/notFound");
      } else {
        setReciepeDetail(d);
      }
    };
    fetchApiReciepeHome();
  }, []);

  const canonical = window.location.href;

  return (
    <section className="box_shodow container reciepe-detail">
      <Helmet>
        <title>{
          `زر اویل / ${reciepeDetail && reciepeDetail.seoTitle}`
          }</title>
        <link rel="canonical" href={canonical} />
        <meta
          name="twitter:title"
          content={reciepeDetail && reciepeDetail.title}
        />
        <meta
          name="twitter:description"
          content={reciepeDetail && reciepeDetail.seoSummary}
        />
        <meta
          name="twitter:image"
          content={
            reciepeDetail &&
            (reciepeDetail.seoImage
              ? reciepeDetail.seoImage
              : reciepeDetail.image)
          }
        />
        <meta
          property="og:title"
          content={reciepeDetail && reciepeDetail.title}
        />
        <meta
          property="og:description"
          content={reciepeDetail && reciepeDetail.seoSummary}
        />
        <meta
          property="og:image"
          content={
            reciepeDetail &&
            (reciepeDetail.seoImage
              ? reciepeDetail.seoImage
              : reciepeDetail.image)
          }
        />
      </Helmet>
      <nav aria-label="Breadcrumb" className="breadcrumb">
        <ol>
          <li>
            <Link to="/" className="breadcrumb-items">
              صفحه اصلی
            </Link>
          </li>
          <li>
            <Link to="/allRecieps" className="breadcrumb-items">
              دستور پخت
            </Link>
          </li>
          <li>{reciepeDetail.title}</li>
        </ol>
      </nav>
      <div className="modall">
        <figure className="modall-img ">
          <img
            id="img"
            src={`${
              window.Configs["ui-base-url"]
            }/Api/V1/ui/Content/Image?entityName=1&imageName=${
              reciepeDetail.image && reciepeDetail.image
            }`}
            alt={reciepeDetail.title}
          />
        </figure>

        <div className="modall-content d_flex">
          <div className="modall-text">
            <h1>{reciepeDetail.title}</h1>
            <div
              className="body"
              dangerouslySetInnerHTML={{ __html: reciepeDetail.body }}
            ></div>
            {/* {desc && <p> {desc}</p>}
          {ingredients && <h4>{ingredients}</h4>}
          {ingredientsItem &&
            ingredientsItem.map((item, index) => (
              <ul key={index}>
                <li>{item}</li>
              </ul>
            ))}
          {prepare && <h4>{prepare}</h4>}
          {prepareItem &&
            prepareItem.map((item, index) => (
              <ul key={index}>
                <label htmlFor="">{item.num}</label> <li>{item.numItem}</li>
              </ul>
            ))}
          {ingredients2 && <h4>{ingredients2}</h4>}
          {ingredientsItem2 &&
            ingredientsItem2.map((item, index) => (
              <ul key={index}>
                <li>{item}</li>
              </ul>
            ))}
          {prepare2 && <h4>{prepare2}</h4>}
          {prepareItem2 &&
            prepareItem2.map((item, index) => (
              <ul key={index}>
                <label htmlFor="">{item.num}</label> <li>{item.numItem}</li>
              </ul>
            ))} */}
            <div className="button f_flex ">
              <Link to="/">
                <button className="btn_shadow">
                  صفحه اصلی <i className="fa fa-home"></i>
                </button>
              </Link>
            </div>
            {/* <Link to="/">
            <button className="close-modall btn_shadow">
              <i className="fa fa-times"></i>
            </button>
          </Link> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecipesDetail;
