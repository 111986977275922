import React, { useContext } from "react";
import "./Recipes.css";
import { dataContext } from "../../context/DataContextProvider";

import "./reciepeHome.css";
import { Link } from "react-router-dom";

const Recipes = () => {
  const { reciepeHome, reciepeHomeImage } = useContext(dataContext);
  return (
    <>
      <section className="recipes" id="recipes">
        <div className="heading text-center">
          <h4>دستور پخت با</h4>
          <h1> زر اویل</h1>
        </div>
        <div className="container">
          <div className="box-container container">
            <div className="left-box-container">
              {reciepeHome.data?.map((value, index) => (
                <Link
                  to={`/recipesDetail/${value.id}/${value.seoUrlTitle}`}
                  key={index}
                >
                  <figure className="box-image-reciepe">
                    <img
                      src={`${window.Configs["ui-base-url"]}/Api/V1/ui/Content/Image?entityName=1&imageName=${value.image}`}
                      alt={value.title}
                    />
                    <p>{value.title}</p>
                  </figure>
                </Link>
              ))}
            </div>
            <div
              className="right-box-container"
              dangerouslySetInnerHTML={{
                __html:
                  reciepeHomeImage.data && reciepeHomeImage.data.items[0].body,
              }}
            ></div>
          </div>
          {/* <Swiper
            style={{
              "--swiper-navigation-color": "#ccc",
              "--swiper-pagination-color": "#ccc",
            }}
            speed={600}
            parallax={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Parallax, Pagination, Navigation]}
            className="mySwiper"
          >
            {reciepeHome.data?.map((value, index) => (
              <SwiperSlide
                key={index}
                style={{
                  backgroundImage: `url(${window.Configs["ui-base-url"]}/Api/V1/ui/Content/Image?entityName=1&imageName=${value.image})`,
                }}
               
              >
                <div className="text-container">
                  <div className="subtitle" data-swiper-parallax="-10000">
                    <h4>{value.title}</h4>
                  </div>
                  <div className="text" data-swiper-parallax="-20000">
                    <p>{value.summary}</p>
                  </div>
                  <div
                    className="button-container"
                    data-swiper-parallax="-30000"
                  >
                    <Link
                      to={`/recipesDetail/${value.id}/${value.seoUrlTitle}`}
                    >
                      <i className="fa fa-arrow-right arrow"></i>
                      <span>دستور پخت ...</span>
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper> */}
        </div>
      </section>
    </>
  );
};

export default Recipes;
