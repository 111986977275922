import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({ id, image, summary, title, seoUrlTitle }) => {
  //   const [modal, setModal] = useState(false);

  //   const toggleModal = () => {
  //     setModal(!modal);
  //   };

  //   if (modal) {
  //     document.body.classList.add("active-modal");
  //   } else {
  //     document.body.classList.remove("active-modal");
  //   }
  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  return (
    <>
      <div className="box btn_shadow ">
        <figure className="img">
          <img
            src={`${window.Configs["ui-base-url"]}/Api/V1/ui/Content/Image?entityName=2&imageName=${image}`}
            alt={title}
          />
        </figure>
        <div className="category d_flex">
          <Link to={`/blogDetail/${id}/${seoUrlTitle}`}>
            <span>{title}</span>
          </Link>
        </div>
        <div className="title">
          <p>{truncate(summary, 210)}</p>
        </div>
        <div className="continue">
          <Link to={`/blogDetail/${id}/${seoUrlTitle}`}>
            <i className="fa fa-arrow-right "></i>
            <span>ادامه مطلب...</span>
          </Link>
        </div>
      </div>

      {/* Popup box */}
      {/* {modal && (
                <div className='modal modal-blog'>
                    <div onClick={toggleModal} className='overlay'></div>
                    <div className='modal-content'>
                        <div className='modal-img left'>
                            <img src={image} alt='' />
                        </div>
                        <div className='modal-text right'>
                            <span>{date}</span>
                            <h1>{title_one}</h1>
                            <p>{desc_one}</p>

                            <h1>{title_two}</h1>
                            <p>{desc_two}</p>

                            <h1>{title_three}</h1>
                            <p>{desc_three}</p>

                            <button className='close-modal btn_shadow' onClick={toggleModal}>
                                <i class='fa fa-times'></i>
                            </button>

                        </div>
                    </div>
                </div>
            )} */}
    </>
  );
};

export default BlogCard;
