import axios from "./axios";
  
  const getSlides = async () => {
  const response = await axios.get(`/api/V1/Ui/Slide/GetSlidesForUi`, {
    headers: {
      SubSystemKey: window.Configs["sub-system-key"],
    },
  });
  return response.data;
};

const getOilProducts = async () => {
  const response = await axios.get(
    `/api/V1/Ui/Product/GetProductsForUi?count=5`,
    {
      headers: {
        SubSystemKey: window.Configs["sub-system-key"],
      },
    }
  );
  return response.data;
};

// const getWebpart = async () => {
//   const response = await axios.get(`/api/V1/Ui/WebPart/GetWebPartsForUi`, {
//     headers: {
//       SubSystemKey: window.Configs["sub-system-key"],
//     },
//   });
//   return response.data;
// };

const getWebpartByName = async (names) => {
  const response = await axios.post(`/api/V1/Ui/WebPart/GetUiWebPartsByNames`,
  names,
   {
    headers: {
      SubSystemKey: window.Configs["sub-system-key"],
      // "Content-Type": "application/json",
    },
    
  });
  return response.data;
};

const getReciepeHome = async () => {
  const response = await axios.get(
    "/api/V1/Ui/Recipe/GetRecipesForUi?count=6",
    {
      headers: {
        SubSystemKey: window.Configs["sub-system-key"],
      },
    }
  );
  return response.data;
};

const getAllReciepe = async (pageNumReciepe , pageSizeReciepe) => {
  const response = await axios.get("/api/V1/Ui/Recipe/GetRecipeListForUi", {
    headers: {
      SubSystemKey: window.Configs["sub-system-key"],
    },
    params: {
      page: `${pageNumReciepe}`,
      pagesize: `${pageSizeReciepe}`,
    },
  });
  return response.data;
};

const getAllProducts = async (pageNumProduct , pageSizeProduct) => {
  const response = await axios.get("/api/V1/Ui/Product/GetProductListForUi", {
    headers: {
      SubSystemKey: window.Configs["sub-system-key"],
    },
    params: {
      page: `${pageNumProduct}`,
      pagesize: `${pageSizeProduct}`,
    },
  });
  return response.data;
};


// const getwebPartAboutUs = async () => {
//   const response = await axios.get(
//     "/api/V1/Ui/WebPart/GetWebPartForUi?uniqueName=aboutUs",
//     {
//       headers: {
//         SubSystemKey: window.Configs["sub-system-key"],
//       },
//     }
//   );
//   return response.data;
// };

const getwebPartFooter = async () => {
  const response = await axios.get(
    "/api/V1/Ui/WebPart/GetWebPartForUi?uniqueName=footer",
    {
      headers: {
        SubSystemKey: window.Configs["sub-system-key"],
      },
    }
  );
  return response.data;
};

// const getwebPartAboutProduct = async () => {
//   const response = await axios.get(
//     "/api/V1/Ui/WebPart/GetWebPartForUi?uniqueName=aboutProduct",
//     {
//       headers: {
//         SubSystemKey: window.Configs["sub-system-key"],
//       },
//     }
//   );
//   return response.data;
// };

const getwebPartContactUs = async () => {
  const response = await axios.get(
    "/api/V1/Ui/WebPart/GetWebPartForUi?uniqueName=contactUs",
    {
      headers: {
        SubSystemKey: window.Configs["sub-system-key"],
      },
    }
  );
  return response.data;
};

// const getAllBlogs = async () => {
//   const response = await axios.get("/api/V1/Ui/Article/GetArticleListForUi", {
//     headers: {
//       SubSystemKey: window.Configs["sub-system-key"],
//     },
//   });
//   return response.data;
// };


const getAllBlogs = async (pageNumBlog , pageSizeBlog) => {
  const response = await axios.get("/api/V1/Ui/Article/GetArticleListForUi", {
    headers: {
      SubSystemKey: window.Configs["sub-system-key"],
    },

    params: {
      page: `${pageNumBlog}`,
      pagesize: `${pageSizeBlog}`,
    },
  });
  return response.data;
};


const getHomeBlogs = async () => {
  const response = await axios.get("/api/V1/Ui/Article/GetArticlesForUi?count=5", {
    headers: {
      SubSystemKey: window.Configs["sub-system-key"],
    },
  });
  return response.data;
};

export {
  getSlides,
  getOilProducts,
  getWebpartByName,
  getReciepeHome,
  getAllReciepe,
  getAllProducts,
  // getwebPartAboutUs,
  getwebPartFooter,
  // getwebPartAboutProduct,
  getwebPartContactUs,
  getAllBlogs,
  getHomeBlogs ,
};
