import React from "react";
import OilProductApi from "./OilProductApi";

const OilProductSlide = ({
  id,
  image,
  summary,
  title,
  specification,
  valueIndex,
  index,
}) => {
  let position = "nextSlide";
  if (valueIndex === index) {
    position = "activeSlide";
  }
  if (
    valueIndex === index - 1 ||
    (index === 0 && valueIndex === OilProductApi.length - 1)
  ) {
    position = "lastSlide";
  }

  const vitamins = JSON.parse(specification).filter((vitamin) => {
    return vitamin.Key === "vitamins";
  });

  const volume = JSON.parse(specification).filter((volume) => {
    return volume.Key === "volume";
  });

  return (
    <>
      <article className={`d_flex container-slider ${position}`} key={id}>
        {/*<div className='box d_flex' className={position} key={id}>*/}
        <div className="left box_shodow">
          <figure className="img">
            <img
              src={`${window.Configs["ui-base-url"]}/Api/V1/ui/Content/Image?entityName=0&imageName=${image}`}
              alt={title}
            />
          </figure>
          <div className="details ">
            <h2>{title}</h2>
            <span className="primary_color">
              {volume.map((v , i) => (
                <span key={i}>
                  <p>{v.Value}</p>
                </span>
              ))}
            </span>
            {vitamins.map((vitamin, index) => (
              <span key={index}>
                <p>{vitamin.Value}</p>
              </span>
            ))}
          </div>
        </div>

        <div className="right">
          <div className="icon">
            <div className="quote">
              <i className="fa fa-quote-right"></i>
            </div>
          </div>

          <div className="content box_shodow">
            <p>{summary}</p>
          </div>
        </div>
      </article>
    </>
  );
};

export default OilProductSlide;
