import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

//api
import {
  getSlides,
  getOilProducts,
  // getWebpart,
  getReciepeHome,
  getAllProducts,
  // getwebPartAboutUs,
  getwebPartFooter,
  // getwebPartAboutProduct,
  getwebPartContactUs,
  getAllBlogs,
  getAllReciepe,
  getWebpartByName,
  getHomeBlogs,
} from "../services/api";

//context
export const dataContext = createContext();

// const getInitialStateSlide = () => {
//   const slide = localStorage.getItem("slide");
//   return slide ? JSON.parse(slide) : [];
// };

// const getInitialStateOilProduct = () => {
//   const oilProduct = localStorage.getItem("oilProduct");
//   return oilProduct ? JSON.parse(oilProduct) : [];
// };

// const getInitialStateWebpart = () => {
//   const webpart = localStorage.getItem("webpart");
//   return webpart ? JSON.parse(webpart) : [];
// };

const DataContextProvider = (props) => {
  const [slides, setSlides] = useState([]);
  const [oilProducts, setOilProducts] = useState([]);
  // const [webParts, setWebParts] = useState({});
  const [reciepeHome, setReciepeHome] = useState([]);
  const [allReciepe, setAllReciepe] = useState([]);
  const [homeBlog , setHomeBlog] = useState([])
  const [allProducts, setAllProducts] = useState({});
  const [aboutUsWebpart, setAboutUsWebpart] = useState({});
  const [reciepeHomeImage, setReciepeHomeImageWebpart] = useState({});
  const [footerWebpart, setFooterWebpart] = useState({});
  const [aboutProductWebpart, setAboutProductWebpart] = useState({});
  const [contactUsWebpart, setContactUsWebpart] = useState({});
  const [allBlogs, setAllBlogs] = useState([]);
  const [isLoadingHome, setIsLoadingHome] = useState(false);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [isLoadingRecieps, setIsLoadingRecieps] = useState(false);
  const [isLoadingBlog, setIsLoadingBlog] = useState(false);
  const [isLoadingContactUs, setIsLoadingContactUs] = useState(false);
  const location = useLocation();

  //pagination Blog
  const [pageNumBlog, setPageNumBlog] = useState(1);
  const [pageSizeBlog, setPageSizeBlog] = useState(12);
  const [pageCountBlog, setPageCountBlog] = useState(0);

  //pagination product
  const [pageNumProduct, setPageNumProduct] = useState(1);
  const [pageSizeProduct, setPageSizeProduct] = useState(12);
  const [pageCountProduct, setPageCountProduct] = useState(0);

  //pagination Reciepe
  const [pageNumReciepe, setPageNumReciepe] = useState(1);
  const [pageSizeReciepe, setPageSizeReciepe] = useState(12);
  const [pageCountReciepe, setPageCountReciepe] = useState(0);

  useEffect(() => {
    if(slides.length===0)
      setIsLoadingHome(true);
    if (location.pathname === "/") {
      const fetchApiSlides = async () => {
        const data = await getSlides()
        setSlides(data);
      };
      fetchApiSlides();
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/") {
      const fetchApiOilProducts = async () => {
        setOilProducts(await getOilProducts());
      };
      fetchApiOilProducts();
    }
  }, [location]);

  // useEffect(() => {
  //   const fetchApiWebparts = async () => {
  //     setWebParts(await getWebpartByName({UniqueNames:["","",""]}));
  //   };
  //   fetchApiWebparts();
  // }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      const fetchApiReciepeHome = async () => {
        setReciepeHome(await getReciepeHome());
      };
      fetchApiReciepeHome();
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/allRecieps") {
      const fetchApiAllReciepe = async (callback) => {
        setIsLoadingRecieps(true);
        setAllReciepe(await getAllReciepe(pageNumReciepe , pageSizeReciepe));
        setIsLoadingRecieps(false);
        allReciepe.data && callback(allReciepe.data.totalCount)
      };
      fetchApiAllReciepe((totalCount) => {
        setPageCountReciepe(Math.ceil(totalCount / pageSizeReciepe))
      });
    }
  }, [location , pageNumReciepe , pageSizeReciepe , allReciepe.data?.totalCount]);

  useEffect(() => {
    if (location.pathname === "/products") {
      const fetchApiAllProducts = async (callback) => {
        setIsLoadingProduct(true);
        setAllProducts(await getAllProducts(pageNumProduct , pageSizeProduct));
        setIsLoadingProduct(false);
        allProducts.data && callback(allProducts.data.totalCount)
      };
      fetchApiAllProducts((totalCount) => {
        setPageCountProduct(Math.ceil(totalCount / pageSizeProduct))
      });
    }
  }, [location,pageNumProduct, pageSizeProduct , allProducts.data?.totalCount]);


  useEffect(() => {
    if (location.pathname === "/") {
      const fetchApiAboutUsWebpart = async () => {
        setAboutUsWebpart(await getWebpartByName({UniqueNames:["aboutUs"]}));
      };
      fetchApiAboutUsWebpart();
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname === "/") {
      const fetchApiReciepeImageWebpart = async () => {
        setReciepeHomeImageWebpart(await getWebpartByName({UniqueNames:["home-reciepe-image"]}));
      };
      fetchApiReciepeImageWebpart();
    }
  }, [location]);

  useEffect(() => {
    const fetchApiFooter = async () => {
      setFooterWebpart(await getwebPartFooter());
    };
    fetchApiFooter();
  }, []);


  useEffect(() => {
    if(location.pathname === "/"){

    const fetchApiAboutProduct = async () => {
      setAboutProductWebpart(await getWebpartByName({UniqueNames:["aboutProduct"]}));
    };
    fetchApiAboutProduct();
  }
  }, [location]);


  useEffect(() => {
    if (location.pathname === "/") {
      const fetchApiHomeBlogs = async () => {
        setHomeBlog(await getHomeBlogs());
      };
      fetchApiHomeBlogs();
    }
  }, [location]);


  useEffect(() => {
    if (location.pathname === "/contactUs") {
      const fetchApiContactUs = async () => {
        setIsLoadingContactUs(true);
        setContactUsWebpart(await getwebPartContactUs());
        setIsLoadingContactUs(false);
      };
      fetchApiContactUs();
    }
  }, [location]);

  // useEffect(() => {
  //   if (location.pathname === "/blog") {
  //     const fetchApiAllBlogs = async () => {
  //       setIsLoadingBlog(true);
  //       setAllBlogs(await getAllBlogs());
  //       setIsLoadingBlog(false);
  //     };
  //     fetchApiAllBlogs();
  //   }
  // }, [location]);

  
  useEffect(() => {
    if (location.pathname === "/blog") {
      const fetchApiAllBlogs = async (callback) => {
        setIsLoadingBlog(true);
        setAllBlogs(await getAllBlogs(pageNumBlog , pageSizeBlog));
        setIsLoadingBlog(false);
        allBlogs.data && callback(allBlogs.data.totalCount);
      };
      fetchApiAllBlogs((totalCount) => {
        setPageCountBlog(Math.ceil(totalCount / pageSizeBlog));
        // console.log("totalCount",totalCount);
      });
    }
  }, [location,pageNumBlog, pageSizeBlog, allBlogs.data?.totalCount]);



  return (
    <dataContext.Provider
      value={{
        slides,
        oilProducts,
        // webParts,
        reciepeHome,
        allReciepe,
        allProducts,
        aboutUsWebpart,
        reciepeHomeImage,
        footerWebpart,
        aboutProductWebpart,
        contactUsWebpart,
        allBlogs,
        isLoadingHome,
        setIsLoadingHome,
        isLoadingBlog,
        isLoadingContactUs,
        isLoadingProduct,
        isLoadingRecieps,
        homeBlog,
        pageNumBlog , pageSizeBlog , setPageSizeBlog , pageCountBlog , setPageNumBlog,
        pageNumReciepe , pageSizeReciepe , setPageSizeReciepe , pageCountReciepe , setPageNumReciepe,
        pageNumProduct , pageSizeProduct , setPageSizeProduct , pageCountProduct , setPageNumProduct,

      }}
    >
      {props.children}
    </dataContext.Provider>
  );
};

export default DataContextProvider;
