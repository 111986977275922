import React from "react";
import { Link } from "react-router-dom";

const RecipesCard = ({ id, image, title, seoUrlTitle }) => {
  return (
    <>
      <div className="box btn_shadow">
        <figure className="img">
          {/* <Link to={`/recipesDetail/${id}/${seoUrlTitle}`}> */}
          <img
            src={`${window.Configs["ui-base-url"]}/Api/V1/ui/Content/Image?entityName=1&imageName=${image}`}
            alt={title}
          />
          {/* </Link> */}
        </figure>

        <div className="title">
          <Link to={`/recipesDetail/${id}/${seoUrlTitle}`}>
            <p>{title}</p>
          </Link>
        </div>
        <div className="button-container">
          <Link to={`/recipesDetail/${id}/${seoUrlTitle}`}>
            <i className="fa fa-arrow-right arrow"></i>
            <span>دستور پخت ...</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default RecipesCard;
