import React, { useState } from "react";
import "./Header.css";
import logo from "../../pic/logo.png";
import { Link } from "react-router-dom";

const Header = ({executeScroll}) => {
  const [pathHome, setPathHome] = useState(true);
  
  // when scroll header at top
  window.addEventListener("scroll", function () {
    const header = this.document.querySelector(".header");
    header.classList.toggle("active", this.window.scrollY > 100);
  });

  const homePathHandler = () => {
    if (window.location.pathname === "/") {
      setPathHome(true);
    } else {
      setPathHome(false);
    }
  };

  //toggle menu
  const [Mobile, setMobile] = useState(false);

  return (
    <nav>
      <header className="header">
        <div className="container d_flex">
          <div className="navlink">
            <ul
              className={Mobile ? "nav-links-mobile" : "link f_flex"}
              onClick={() => setMobile(false)}
            >
              <li onClick={homePathHandler}>
                <Link to="/">صفحه اصلی</Link>
              </li>
              {pathHome && (
                <li onClick={executeScroll}>
                  <Link to="">درباره زراویل</Link>
                </li>
              )}

              <li onClick={homePathHandler}>
                <Link to="/products">محصولات</Link>
              </li>
              <li onClick={homePathHandler}>
                <Link to="/allRecieps">دستور پخت با زراویل</Link>
              </li>
              <li onClick={homePathHandler}>
                <Link to="/blog">اخبار و مقالات</Link>
              </li>
              <li onClick={homePathHandler}>
                <Link to="/contactUs">تماس با ما</Link>
              </li>
              {/* <li>
              <button className="home-btn">خرید</button>
            </li> */}
            </ul>

            <button className="toggle" onClick={() => setMobile(!Mobile)}>
              {Mobile ? (
                <i className="fa fa-times close home-btn"></i>
              ) : (
                <i className="fa fa-bars open"></i>
              )}
            </button>
          </div>
          <figure className="logo" onClick={homePathHandler}>
            <Link to="/">
              {" "}
              <img src={logo} alt="logo" />{" "}
            </Link>
          </figure>
        </div>
      </header>

      <section className="demo"></section>
    </nav>
  );
};

export default Header;
