import React, { useContext } from "react";
import { dataContext } from "../../context/DataContextProvider";
import "./Footer.css";
const Footer = () => {
  const { footerWebpart } = useContext(dataContext);

  const filetrKeyAboutUs = (key) => {
    if (footerWebpart.data) {
      const result = JSON.parse(footerWebpart.data.specification).filter(
        (item) => {
          return item.Key === key;
        }
      );
      return result;
    }
  };

  const footerLogo =
    footerWebpart.data &&
    filetrKeyAboutUs("footerLogo").map((item) => item.Value);

  return (
    <>
      <footer>
        <div className="container text-center  ">
          <figure className="img">
            <img src={footerLogo} alt="footerLogo" />
          </figure>
          <div
            dangerouslySetInnerHTML={{
              __html: footerWebpart.data && footerWebpart.data.body,
            }}
          ></div>
          {/* <div className="footer-contact">
                        <div className="footer-contact-right">
                            <h1>اطلاعات تماس</h1>
                            <div><p><strong>آدرس: </strong> خیابان ملاصدرا، خیابان شیخ بهایی شمالی،نرسیده به میدان شیخ بهایی، نبش کوچه دانش دوست، پلاک ۱۰۳</p></div>
                            <div><p><strong>دفتر تهران: </strong>87700400-021</p></div>
                            <div><p><strong>کدپستی: </strong>1991793963</p></div>
                            <div><p><strong>فکس: </strong>88049907-021</p></div>
                            <div><p><strong>کارخانه: </strong>هشتگرد، پالایشگاه غلات زر</p></div>
                            <div><p><strong>تلفن کارخانه: </strong>43212000-026</p></div>
                        </div>
                        <div className="footer-contact-left">
                            <h1>ارتباط با مشتری</h1>
                            <div><p><strong>ایمیل: </strong>CRM@zarmacaron.ir</p></div>
                            <div><p><strong>تلفن: </strong>۰۲۶-۴۴۳۸۳۹۱۴</p></div>
                            <div className='f_flex'>
                                <button className='btn_shadow'>
                                    <i className='fa fa-facebook-f'></i>
                                </button>
                                <button className='btn_shadow'>
                                    <i className='fa fa-instagram'></i>
                                </button>
                                <button className='btn_shadow'>
                                    <i className='fa fa-twitter'></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="copy-right">
                        <p>تمامی حقوق برای زر اویل © محفوظ است.</p>
                    </div> */}
        </div>
      </footer>
    </>
  );
};

export default Footer;
