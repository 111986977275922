import React, { useContext, useState } from "react";
import axios from "../../services/axios";
import { dataContext } from "../../context/DataContextProvider";

//toastify
import { ToastContainer } from "react-toastify";
import { notify } from "../Validate/toast";
import "react-toastify/dist/ReactToastify.css";
import "./ContactUs.css";
import Loading from "../loading/Loading";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import image from "../../pic/download.jfif"

const ContactUs = () => {
  const [data, setData] = useState({
    Name: "",
    Mobile: "",
    Email: "",
    Subject: "",
    Text: "",
  });
  const [errorMsg, setErrorMsg] = useState({});
  const { contactUsWebpart, isLoadingContactUs } = useContext(dataContext);

  useEffect(() => {
    document.title = "زر اویل / ارتباط با ما";
  }, []);

  const changeHandler = (e) => {
    // const { name, value } = event.target;

    // setData({ ...data, [name]: value });

    const newdata = { ...data };
    newdata[e.target.name] = e.target.value;
    setData(newdata);
  };

  //   const touchedHandler = (event) => {
  //     setTouched({
  //       ...touched,
  //       [event.target.name]: true,
  //     });
  //   };

  let config = {
    method: "post",
    url: "/api/V1/Ui/ContactUs/SubmitContactUs",
    headers: {
      SubSystemKey: window.Configs["sub-system-key"],
      "Content-Type": "application/json",
    },
    data: data,
  };

  const submitHandler = (event) => {
    event.preventDefault();
    axios(config)
      .then(function (response) {
        notify(response.data.message, "success");
        setErrorMsg({
          Name: "",
          Mobile: "",
          Email: "",
          Subject: "",
          Text: "",
        });

        setData({
          Name: "",
          Mobile: "",
          Email: "",
          Subject: "",
          Text: "",
        });
      })
      .catch(function (error) {
        if (error.response.status === 400) {
          notify("فیلدها را به صورت صحیح وارد کنید.", "error");
          for (const item in error.response.data.errors) {
            errorMsg[item] = error.response.data.errors[item][0];
          }
          setErrorMsg({ ...errorMsg });
        }
      });
  };

  // const iframe = `<iframe src=${window.Configs["map-base-src"]}' +
  //   ' style="border:0;" allowFullScreen="" loading="lazy" ' +
  //   'referrerPolicy="no-referrer-when-downgrade" class="map-frame"></iframe>`;

  // const mapFunc = () => {
  //   return (
  //     <div>
  //       <div
  //         dangerouslySetInnerHTML={{
  //           __html: iframe,
  //         }}
  //       />
  //     </div>
  //   );
  // };

  const canonical = window.location.href;


  //leaflet map
  const position = [35.906642361109554, 50.819311268323204];

  let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
  });
  L.Marker.prototype.options.icon = DefaultIcon;

  return (
    <>
      {isLoadingContactUs ? (
        <Loading />
      ) : (
        <section className="Contact" id="contact">
          <Helmet>
            <title>زر اویل / ارتباط با ما</title>
            <link rel="canonical" href={canonical} />
          </Helmet>
          <div className="container">
            <div className="heading text-center">
              {/* <h4>CONTACT</h4> */}
              <h1>ارتباط با ما </h1>
            </div>

            <div className="content d_flex">
              <div className="right box_shodow">
                <form onSubmit={submitHandler}>
                  <div className="f_flex">
                    <div className="input ">
                      <input
                        type="text"
                        placeholder="نام"
                        style={{
                          border: errorMsg.Name ? "1px solid red" : "",
                        }}
                        name="Name"
                        value={data.Name}
                        onChange={changeHandler}
                      />
                      {errorMsg.Name && (
                        <span className="error">{errorMsg.Name}</span>
                      )}
                    </div>

                    <div className="input ">
                      <input
                        type="tel"
                        placeholder="تلفن"
                        style={{
                          border: errorMsg.Mobile ? "1px solid red" : "",
                        }}
                        name="Mobile"
                        value={data.Mobile}
                        onChange={changeHandler}
                      />
                      {errorMsg.Mobile && (
                        <span className="error">{errorMsg.Mobile}</span>
                      )}
                    </div>
                  </div>
                  <div className="f_flex">
                    <div className="input ">
                      <input
                        type="email"
                        placeholder="ایمیل"
                        style={{
                          border: errorMsg.Email ? "1px solid red" : "",
                        }}
                        name="Email"
                        value={data.Email}
                        onChange={changeHandler}
                      />
                      {errorMsg.Email && (
                        <span className="error">{errorMsg.Email}</span>
                      )}
                    </div>

                    <div className="input ">
                      <input
                        type="text"
                        placeholder="موضوع"
                        style={{
                          border: errorMsg.Subject ? "1px solid red" : "",
                        }}
                        name="Subject"
                        value={data.Subject}
                        onChange={changeHandler}
                      />
                      {errorMsg.Subject && (
                        <span className="error">{errorMsg.Subject}</span>
                      )}
                    </div>
                  </div>

                  <div className="f_flex">
                    <div className="input ">
                      <textarea
                        cols="30"
                        rows="7"
                        placeholder="متن پیام"
                        style={{
                          border: errorMsg.Text ? "1px solid red" : "",
                        }}
                        name="Text"
                        value={data.Text}
                        onChange={changeHandler}
                      ></textarea>
                      {errorMsg.Text && (
                        <span className="error">{errorMsg.Text}</span>
                      )}
                    </div>
                  </div>

                  <div className="last-input">
                    <button className="">
                      <i className="fa fa-long-arrow-right"></i>ارسال پیام
                    </button>
                  </div>
                </form>
              </div>

              <div className="left">
                <div className="box box_shodow">
                  <div className="img">
                    {/* {mapFunc()} */}
                    <MapContainer
                      center={position}
                      zoom={13}
                      scrollWheelZoom={false}
                    >
                      <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker position={position}>
                        <Popup className="popup-map">
                          <img src={image} alt="" />
                          <p>
                            خیابان ملاصدرا،خیابان شیخ بهایی شمالی،نرسیده به
                            میدان شیخ بهایی، نبش کوچه دانش دوست، پلاک ۱۰۳
                          </p>
                        </Popup>
                      </Marker>
                    </MapContainer>
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        contactUsWebpart.data && contactUsWebpart.data.body,
                    }}
                  ></div>
                  {/* <div className="details">
                  <h1>اطلاعات تماس</h1>
                  <div>
                  <p>
                  <strong>آدرس: </strong> خیابان ملاصدرا، خیابان شیخ بهایی
                      شمالی،نرسیده به میدان شیخ بهایی، نبش کوچه دانش دوست، پلاک
                      ۱۰۳
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>دفتر تهران: </strong>87700400-021
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>کدپستی: </strong>1991793963
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>فکس: </strong>88049907-021
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>کارخانه: </strong>هشتگرد، پالایشگاه غلات زر
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>تلفن کارخانه: </strong>43212000-026
                    </p>
                  </div>
                  <h1>ارتباط با مشتری</h1>
                  <div>
                    <p>
                      <strong>ایمیل: </strong>CRM@zarmacaron.ir
                    </p>
                  </div>
                  <div>
                    <p>
                      <strong>تلفن: </strong>۰۲۶-۴۴۳۸۳۹۱۴
                    </p>
                  </div>
                  <div className="button f_flex">
                    <button className="btn_shadow">
                      <i className="fa fa-facebook-f"></i>
                    </button>
                    <button className="btn_shadow">
                      <i className="fa fa-instagram"></i>
                    </button>
                    <button className="btn_shadow">
                      <i className="fa fa-twitter"></i>
                    </button>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
          <ToastContainer rtl={true} />
        </section>
      )}
    </>
  );
};

export default ContactUs;
