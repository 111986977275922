import React, { useEffect, useState } from "react";
import axios from "../../services/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./BlogDetails.css";
import { Helmet } from "react-helmet-async";

const BlogDetaills = () => {
  const { id } = useParams();
  const { seoUrlTitle } = useParams();
  const navigate = useNavigate();
  const [blogDetail, setBlogDetail] = useState([]);
  const getArticleDetail = async () => {
    const response = await axios.get(
      `/api/V1/Ui/Article/GetArticleDetailForUi/${id}`,
      {
        headers: {
          SubSystemKey: window.Configs["sub-system-key"],
        },
      }
    );
    return response.data.data;
  };

  useEffect(() => {
    const fetchApiBlog = async () => {
      const d = await getArticleDetail();

      if (encodeURI(d.seoUrlTitle) !== encodeURI(seoUrlTitle)) {
        navigate("/notFound");
      } else {
        setBlogDetail(d);
      }
    };
    fetchApiBlog();
  }, []);

  const canonical = window.location.href;
  return (
    <section>
      <Helmet>
        <title>{`زر اویل / ${blogDetail.seoTitle && blogDetail.seoTitle}`}</title>
        <link rel="canonical" href={canonical} />
        <meta name="twitter:title" content={blogDetail && blogDetail.title} />
        <meta
          name="twitter:description"
          content={blogDetail && blogDetail.seoSummary}
        />
        <meta
          name="twitter:image"
          content={
            blogDetail &&
            (blogDetail.seoImage ? blogDetail.seoImage : blogDetail.image)
          }
        />
        <meta property="og:title" content={blogDetail && blogDetail.title} />
        <meta
          property="og:description"
          content={blogDetail && blogDetail.seoSummary}
        />
        <meta
          property="og:image"
          content={
            blogDetail &&
            (blogDetail.seoImage ? blogDetail.seoImage : blogDetail.image)
          }
        />
      </Helmet>
      <div className="blog-detail box_shodow container">
        <nav aria-label="Breadcrumb" className="breadcrumb">
          <ol>
            <li>
              <Link to="/" className="breadcrumb-items">
                صفحه اصلی
              </Link>
            </li>
            <li>
              <Link to="/blog" className="breadcrumb-items">
                اخبار و مقالات
              </Link>
            </li>
            <li>{blogDetail.title}</li>
            {/* <li>
            <a href="./index.html" aria-current="page">
              Breadcrumb Example
            </a>
          </li> */}
          </ol>
        </nav>
        {/* {blogDetail.image && (
          <div className="blog-detail-img">
            <img
              src={`${
                window.Configs["ui-base-url"]
              }/Api/V1/ui/Content/Image?entityName=2&imageName=${
                blogDetail.image && blogDetail.image
              }`}
              alt=""
            />
          </div>
        )} */}
        <div className="blog-detail-title">
          <h1>{blogDetail.title && blogDetail.title}</h1>
        </div>
        <div
          className="blog-detail-body"
          dangerouslySetInnerHTML={{ __html: blogDetail.body }}
        ></div>
      </div>
    </section>
  );
};

export default BlogDetaills;
