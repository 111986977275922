// const shorten = (title) => {
//     const splitTitle = title.split(" ");
//     const newTitle = `${splitTitle[0]} ${splitTitle[1]} ${splitTitle[2]} ${splitTitle[3]} ${splitTitle[4]} ${splitTitle[5]} ${splitTitle[6]} ${splitTitle[7]} ${splitTitle[8]} ${splitTitle[9]} ${splitTitle[10]} `;
//     return newTitle;
// };
const shorten = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) +"..." : str;
};




export { shorten };
