import image from "../pic/M5.jpg"
const OilProductApi = [
    // {
    //     id: 1,
    //     name: "روغن ذرت",
    //     liter: "1.8",
    //     vitamin: "سرشار از ویتامین و اسیدهای چرب ضروری",
    //     image: { image },
    //     title: "روغن سرخ کردنی ذرت حاوی کنجد و بدون پالم زراویل",
    //     desc: "مجموعه زر اویل در طی سال‌های فعالیت خود با کمک گروه صنعتی و پژوهشی و همین‌طور مبتنی بر استانداردهای سلامت به تولید روغن ذرت با بالاترین کیفیت در میان رقبا می‌پردازد و آن را به بازار مصرفی مشتریان خود وارد می‌کند. روغن ذرت زر اویل در حال حاضر به دلیل کیفیت بالای تولید در رده باکیفیت‌ترین روغن‌های سرخ‌کردنی بازار قرار گرفته و به عنوان شایسته‌ترین محصول از نظر مصرف‌کننده نیز معرفی شده است. در این مطلب تصمیم داریم تا از روش تهیه روغن ذرت در زر اویل برایتان بگوییم تا با دلیل شایستگی و کیفیت این محصول نیز آگاه شوید.",
    //     desc2:"",
    // },
    {
        id: 1,
        name: "روغن ذرت و آفتابگردان",
        liter: "1.8 لیتر",
        vitamin: "حاوی ویتامین E، آنتی‌اکسیدان و امگا 3",
        image: { image },
        title: "روغن سرخ کردنی ذرت حاوی کنجد و بدون پالم زراویل",
        desc: "ویتامین E یکی از ریزمغذی هایی است که در روغن ذرت و آفتابگردان وجود دارد و برای سلامت پوست و سیستم ایمنی بدن مفید است. این روغن حاوی آنتی اکسیدان هایی با خواص ضد التهابی است که در فرایند روغن گیری حذف نمی شود. ذرت حاوی امگا3 بوده و برای سلامت قلب و عروق، کاهش بیماری های التهابی، درد مفاصل، کاهش افسردگی، تقویت سیستم ایمنی و سلامت پوست مفید است.",
        desc2:"امگا6 که در این روغن وجود دارد در مقدار مجاز می‌تواند سلامت استخوان را افزایش داده و به کاهشِ فشار خون و سلامت قلب کمک کند. همچنین این روغن بدون پالم است." , 
    },
    {
        id: 2,
        name: "روغن جوانه ذرت",
        liter: "900 میلی لیتری و 1.8 لیتر",
        vitamin: "حاوی آنتی‌اکسیدان، امگا 6 و امگا 9 و ویتامین E",
        image: { image },
        title: "روغن سرخ کردنی ذرت حاوی کنجد و بدون پالم زراویل",
        desc: "روغن جوانه ذرت به عنوان یک محصول فراسودمند و سلامت محورسرشار از ویتامین E، آنتی اکسیدان ها، امگا ۶ و امگا ۹ است. این روغن بدون پالم است. از ویژگی‌های روغن بر پایه ذرت، می‌توان به نقطه دود بالای آن اشاره کرد که سبب مقاومت در مقابل حرارت بالا، می گردد. همچنین روغن ذرت زر اویل، مناسب برای تفت دادن، پخت و پز و سالاد است.",
        desc2:"روغن جوانه ذرت زر اویل، در حجم های 900 میلی لیتری و 1.8 لیتری بسته بندی و ارائه می گردد.",
    },
    {
        id: 3,
        name: "روغن ذرت و کانولا",
        liter: "1.8 لیتر",
        vitamin: "حاوی اسیدهای چرب امگا 3 و امگا 6 ",
        image: { image },
        title: "روغن سرخ کردنی ذرت حاوی کنجد و بدون پالم زراویل",
        desc: " روغن مایع مخلوط ذرت و کانولا زر اویل، حاوی اسیدهای چرب ضروری مانند امگا3 و امگا6 است که می‌تواند التهابات بدن را کاهش دهد. علاوه براین به دلیل حضور امگا3 در این روغن، در بهبود علائم بیماری ام اس، مبارزه با افسردگی و اضطرابو جلوگیری از سرطان، نقش به‌سزایی دارد.",
        desc2:"از سوی دیگر روغن ذرت و کانولا زر اویل، حاوی مقادیر زیادی فیتواسترول است که به پایین آمدن سطح کلسترول خون کمک شایانی می‌کند. این روغن برای پخت و پز و سالاد مناسب است و با داشتن انواع ویتامین‌ها و سایر مواد معدنی مهم، بخش قابل توجهی از نیازهای بدن را تامین می‌کند. همچنین این روغن بدون پالم است.",
    },
    {
        id: 4,
        name: "روغن ویتامینه ذرت زراویل ",
        liter: "1.8 لیتر",
        vitamin: "غنی شده با ویتامینه D",
        image: { image },
        title: "روغن سرخ کردنی ذرت حاوی کنجد و بدون پالم زراویل",
        desc: "روغن غنی شده با ویتامین D زر اویل، به بدن برای جذب کلسیم، رشد و استحکام استخوان ها، کمک می‌کند. کمبود ویتامین D باعث بروز مشکلات زیادی از جمله پوکی و نرمی استخوان، سرطان و افسردگی در بزرگسالان می‌شود. یکی از موثرترین راه‌ها برای جبران این کمبود، افزودن این ویتامین به سبد غذایی خانوارها است. غنی سازی روغن‌های خوراکی با ویتامین دی، نتایج قابل قبولی بر سلامت مصرف کنندگان دارد. روغن ذرت غنی شده با ویتامین دی و بدون پالم زر اویل، مناسب برای افرادی است که به سلامت خود اهمیت می‌دهند.",
        desc2:"",
    },
    {
        id: 5,
        name: "روغن سرخ کردنی ذرت حاوی کنجد و بدون پالم",
        liter: "900 میلی لیتر و 1.8 لیتر",
        vitamin: " حاوی اسیدهای چرب امگا 3 و امگا 6 و امگا 9",
        image: { image },
        title: "روغن سرخ کردنی ذرت حاوی کنجد و بدون پالم زراویل",
        desc: "روغن سرخ کردنی زر اویل از ترکیب سه روغن ذرت، کنجد، کانولا تهیه شده است. از ویژگی های روغن سرخ کردنی زر اویل می توان به موارد ذیل اشاره کرد:",
        ul:["بدون پالم" , "نقطه ی دود 220 درجه سانتی گراد" , "پایداری اکسیداتیو و عدم واکنش سریع با اکسیژن در دمای بالا" , "حاوی اسیدهای چرب تک غیر اشباع و چند غیر اشباع مانند امگا3، امگا6 و امگا9"],
        desc2:"روغن سرخ کردنی زر اویل، در حجم های 900 میلی لیتری و 1.8 لیتری بسته بندی و ارائه می گردد.                    ",
    },
]

export default OilProductApi