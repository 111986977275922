import React, { useContext } from "react";
import Slider from "react-slick";
import "./Slideshow.css";

import { dataContext } from "../../context/DataContextProvider";
const Slideshow = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };

  const { slides,  setIsLoadingHome } = useContext(dataContext);
  
const onLoadHandle = ()=>{
  setIsLoadingHome(false);
}


  return (
    
    <article className="container slider-container">
      <Slider {...settings} className="slider">
        {slides.data && slides.data.map((item, index) => {
          return (
            <figure className="image" key={index}>
              <img
                src={`${window.Configs["ui-base-url"]}/Api/V1/ui/Content/Image?entityName=3&imageName=${item.image}`}
                alt={item.title}
                onLoad={onLoadHandle}
              />
            </figure>
          );
        })}
      </Slider>
    </article>
  );
};

export default Slideshow;
