import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import "./NotFound.css";

const NotFound = () => {
  const canonical = window.location.href;
  return (
    <section className="page_404">
      <Helmet>
        <title>404 Not Found</title>
        <link rel="canonical" href={canonical} />
      </Helmet>
      <div className="four_zero_four_bg">
        <h1 className="text-center ">404</h1>
      </div>

      <div className="contant_box_404">
        <p>صفحه مورد نظر یافت نشد !</p>
        <Link to="/" className="link_404">
          بازگشت به صفحه اصلی
        </Link>
      </div>
    </section>
  );
};

export default NotFound;
